import React, { useState, useEffect, useCallback } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Image from 'common/components/Image';
import { Icon } from 'react-icons-kit';
import { ic_check_circle } from 'react-icons-kit/md/ic_check_circle';
import { closeCircled } from 'react-icons-kit/ionicons/closeCircled';
import Container from 'common/components/UI/Container';
import Button from 'common/components/Button';
import TagManager from 'react-gtm-module'
import { SectionHeader } from '../appCreative.style';
import illustration from 'common/assets/image/appCreative/shapeRight1.png';
import illustration2 from 'common/assets/image/appCreative/shapeRight2.png';
import SectionWrapper, {
  SectionBgArea,
  ContentWrapper,
  ContentPricing,
  PriceTable,
  PricingFeature,
  FeatureItem,
  ContentWrap,
  ButtonWrap,
} from './pricing.style';


const tagManagerArgs = {
  gtmId: 'GTM-P3PVRNQ',
  dataLayer: {
    page: 'Pricing',
    event: 'payment' //book_appointment
  }
}

function useWindowSize() {
  const isClient = typeof window === 'object';

  const getSize = useCallback(() => {
    return {
      innerWidth: isClient ? window.innerWidth : undefined,
      innerHeight: isClient ? window.innerHeight : undefined,
    };
  }, [isClient]);

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isClient, getSize]); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

const Pricing = () => {
  const size = useWindowSize();
  const isTablet = Boolean(size.width <= 768);

  const data = useStaticQuery(graphql`
    query {
      appCreativeJson {
        pricing {
          title
          slogan
          pricingFeature {
            id
            name
          }
          pricingItems {
            id
            package_name
            price
            trial_day
            isRecommended
            features {
              id
              name
              isAvailable
            }
          }
        }
      }
    }
  `);

  const { slogan, title, pricingFeature, pricingItems } =
    data.appCreativeJson.pricing;
  return (
    <SectionWrapper id="pricing">
      {
      <SectionBgArea>
        <div className="shape-one">
          <Image src={illustration} alt="Shape" />
        </div>
        <div className="shape-two">
          <Image src={illustration2} alt="Shape" />
        </div>
      </SectionBgArea>
      }
      <Container>
        <SectionHeader className="text-black">
          <Heading content={title} />
          <Text content={slogan} />
        </SectionHeader>
        <ContentWrapper>
          <ContentPricing>
            {!isTablet && (
              <PricingFeature>
                {pricingFeature.map((feature) => (
                  <FeatureItem key={feature.id}>{feature.name}</FeatureItem>
                ))}
              </PricingFeature>
            )}
            {pricingItems.map((priceTable) => (
              <PriceTable
                key={priceTable.id}
                className={priceTable.isRecommended && 'isRecommended'}
              >
                {priceTable.isRecommended && (
                  <div className="recommended">Best value</div>
                )}

                <h2 className="title">{priceTable.package_name}</h2>
                <div className="price">
                  ${priceTable.price}
                  <span>/ Month.</span>
                </div>
                <ul className="featureList">
                  {priceTable.features.map((feature) => (
                    <FeatureItem key={feature.id}>
                      {isTablet ? (
                        feature.isAvailable ? (
                          feature.name
                        ) : (
                          <Icon
                            icon={closeCircled}
                            size={18}
                            style={{ color: '#CED7E1' }}
                          />
                        )
                      ) : feature.isAvailable ? (
                        <Icon
                          icon={ic_check_circle}
                          size={18}
                          style={{ color: '#3CC68A' }}
                        />
                      ) : (
                        <Icon
                          icon={closeCircled}
                          size={18}
                          style={{ color: '#CED7E1' }}
                        />
                      )}
                    </FeatureItem>
                  ))}
                </ul>
                {
                <Button
                  onClick={() => {
                  window.location.href="https://payments.h2zero.bm";
                  TagManager.initialize(tagManagerArgs)
                }}
                  title="Choose Plan"
                  className={`${
                    !priceTable.isRecommended && 'primaryOutlined'
                  } choosePlan`}

                />
                }
                <p className="trial">${priceTable.trial_day} /Yr</p>
              </PriceTable>
            ))}
          </ContentPricing>
          <ContentWrap>
            <Heading
              as="h3"
              content="Own a business? Let’s talk about our business pricing."
            />
            <ButtonWrap>
              {/*<Button title="Contact Us Now!" />*/}
            </ButtonWrap>
          </ContentWrap>
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default Pricing;
